import React from "react";
import "../styles/Footer.css";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
    const location = useLocation();
    return (
        <div className="footerContainer">
            <div className="mainfooter">
                <p>
                    <a href="tel:7205417409">(720) 541-7409</a>
                </p>
                <p>
                    <a
                        href="https://www.google.com/maps/place/TJ's+Sports+Bar+%26+Grill/@39.6838087,-105.0856355,21z/data=!4m6!3m5!1s0x876b8194eb1c843b:0xbf256f439916e507!8m2!3d39.6837477!4d-105.0857028!16s%2Fg%2F11hk5xf1_x?entry=ttu"
                        target="_blank"
                        rel="noreferrer"
                    >
                        7893 W Jewell Ave, Lakewood, CO 80232
                    </a>
                </p>
            </div>
            <div className="socialFooter">
                <p>
                    <a
                        href="https://www.facebook.com/tjssportsbarandgrill/"
                        target="_black"
                        rel="noreferrer"
                    >
                        <FontAwesomeIcon icon={faFacebook} size="2x" />
                    </a>
                </p>
                <p>
                    <a
                        href="https://www.instagram.com/tjssportsbar/"
                        target="_black"
                        rel="noreferrer"
                    >
                        <FontAwesomeIcon icon={faInstagram} size="2x" />
                    </a>
                </p>
            </div>
            <Link
                to="/signin"
                className={
                    location.pathname === "/signin" ? "selectedLink" : ""
                }
            >
                Login
            </Link>
        </div>
    );
};

export default Footer;
