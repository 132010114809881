import React, { useState, useEffect } from "react";
import {
    collection,
    getDocs,
    addDoc,
    deleteDoc,
    doc,
} from "firebase/firestore";
import "../styles/CalendarPage.css";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import Navbar from "./NavBar";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
    authDomain: "tjsportsbar-b2424.firebaseapp.com",
    projectId: "tjsportsbar-b2424",
    storageBucket: "tjsportsbar-b2424.appspot.com",
    messagingSenderId: "916749962972",
    appId: "1:916749962972:web:9603ea2103f1d275a72e19",
    measurementId: "G-GGYXJNGSC8",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const CalendarPage = ({ ...props }) => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formRefresh, setFormRefresh] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        date: "",
        time: "",
        description: "",
    });

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const querySnapshot = await getDocs(
                    collection(firestore, "events")
                );
                const eventData = [];
                querySnapshot.forEach((doc) => {
                    const eventDateString = doc.data().date;
                    const eventDate = new Date(eventDateString + "T00:00:00Z"); // Parse as UTC

                    // Check if the event is today or in the future
                    const currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0);

                    if (eventDate >= currentDate) {
                        eventData.push({
                            id: doc.id,
                            ...doc.data(),
                            date: eventDate,
                        });
                    }
                });
                eventData.sort((a, b) => {
                    const dateA = a.date.setHours(0, 0, 0, 0);
                    const dateB = b.date.setHours(0, 0, 0, 0);
                    if (dateA !== dateB) {
                        return dateA - dateB;
                    }
                    const [hoursA, minutesA] = a.time.split(":").map(Number);
                    const [hoursB, minutesB] = b.time.split(":").map(Number);
                    return hoursA * 60 + minutesA - (hoursB * 60 + minutesB);
                });

                setEvents(eventData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching events:", error);
                setLoading(false);
            }
        };
        fetchEvents();
    }, [formRefresh]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const utcDate = new Date(formData.date + "T00:00:00Z")
                .toISOString()
                .split("T")[0];
            await addDoc(collection(firestore, "events"), {
                name: formData.name,
                date: utcDate,
                time: formData.time,
                description: formData.description,
            });
            setFormData({
                name: "",
                date: "",
                time: "",
                description: "",
            });
            setFormRefresh(!formRefresh);
        } catch (error) {
            console.error("Error adding event:", error);
        }
    };

    const handleDelete = async (eventId) => {
        try {
            await deleteDoc(doc(firestore, "events", eventId));
            setFormRefresh(!formRefresh);
        } catch (error) {
            console.error("Error deleting event:", error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    const sortedEvents = [...events].sort((a, b) => a.date - b.date);

    const convertToAMPM = (militaryTime) => {
        const [hours, minutes] = militaryTime.split(":");
        const hour = parseInt(hours, 10);
        const ampm = hour >= 12 ? "PM" : "AM";
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        return `${formattedHour}:${minutes} ${ampm}`;
    };

    const addOneDay = (date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        return newDate;
    };

    return (
        <div className="events-container">
            <Navbar />
            <h1>UPCOMING EVENTS</h1>
            {props.isAdmin && (
                <div className="form-container">
                    <h2>Add New Event</h2>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Name of Event:
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <label>
                            Date of Event:
                            <input
                                type="date"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <label>
                            Time of Event:
                            <input
                                type="time"
                                name="time"
                                value={formData.time}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <label>
                            Description of Event:
                            <textarea
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <button type="submit">Add Event</button>
                    </form>
                </div>
            )}
            <div className="events-grid">
                {sortedEvents.map((event) => (
                    <div key={event.id} className="event-card">
                        <div className="event-details">
                            <h2>{event.name}</h2>
                            <p>
                                Date:{" "}
                                {addOneDay(event.date).toLocaleDateString(
                                    "en-US"
                                )}
                            </p>
                            <p>Time: {convertToAMPM(event.time)}</p>
                            <p>{event.description}</p>
                            {props.isAdmin && (
                                <button onClick={() => handleDelete(event.id)}>
                                    Delete
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CalendarPage;
