import React, { useState } from "react";
import "../styles/Menu.css";
import Navbar from "./NavBar";
import Footer from "./Footer";

const Menu = ({ ...props }) => {
    const [menuType, setMenuType] = useState("food");
    return (
        <div className="menu-page">
            <Navbar />
            <div
                className={`${
                    menuType === "specials" && "selectedMenuContainer"
                } menu-container`}
            >
                <section className="menuNav">
                    <h6
                        onClick={() => setMenuType("food")}
                        className={`${menuType === "food" && "selected"}`}
                    >
                        Food
                    </h6>
                    <h6
                        onClick={() => setMenuType("beverages")}
                        className={`${menuType === "beverages" && "selected"}`}
                    >
                        Beverages
                    </h6>
                    <h6
                        onClick={() => setMenuType("specials")}
                        className={`${menuType === "specials" && "selected"}`}
                    >
                        Specials
                    </h6>
                </section>
                {menuType === "food" && (
                    <>
                        <section className="food-container">
                            <div className="leftside">
                                <div className="appetizers">
                                    <h2>APPETIZERS</h2>
                                    <div className="firstElement">
                                        <h4>FRIED PICKELS</h4>
                                    </div>
                                    <div>
                                        <h4>JALAPENO POPPERS</h4>
                                    </div>
                                    <div>
                                        <h4>PRETZEL BITES</h4>
                                        <p>served with home-made queso</p>
                                    </div>
                                    <div>
                                        <h4>CHEESE CURDS</h4>
                                    </div>
                                    <div>
                                        <h4>BUFFALO CAULIFLOWER</h4>
                                    </div>
                                    <div>
                                        <h4>LOADED NACHOS</h4>
                                        <p>
                                            tortilla chips loaded with black
                                            beans, pork green chili, pico,
                                            queso, queso, sour cream, shredded
                                            cheese, pickled red onion and
                                            jalapenos
                                        </p>
                                    </div>
                                    <div>
                                        <h4>HOMEMADE PORK GREEN CHILI</h4>
                                        <p>choice of corn or flour tortillas</p>
                                    </div>
                                    <div>
                                        <h4>CHIPS AND DIPS</h4>
                                        <p>
                                            tortilla chips with your choice of
                                            homemade queso, salsa or guacamole
                                        </p>
                                    </div>
                                    <div>
                                        <h4>GREEN CHILI MAC N CHEESE</h4>
                                    </div>
                                    <div>
                                        <h4>QUESADILLA</h4>
                                        <p>
                                            add shredded chicken, shredded pork,
                                            or ground beef
                                        </p>
                                    </div>
                                    <div>
                                        <h4>LIL STREET TACOS</h4>
                                        <p>choice of pork, chicken or beef</p>
                                    </div>
                                    <div>
                                        <h4>CHICKEN WINGS</h4>
                                        <p>
                                            your choice of Buffalo, BBQ, Dry
                                            Rub, Lemon Pepper, Garlic Parmesan
                                            or Habanero. Served with celery,
                                            carrots and choice of ranch or blue
                                            cheese dressing
                                        </p>
                                    </div>
                                </div>
                                <div className="divider"></div>
                                <div className="salads">
                                    <h2>SALADS</h2>
                                    <div className="dressings">
                                        <h5>DRESSINGS</h5>
                                        <p>
                                            ranch | spicy ranch | blue cheese |
                                            balsamic | honey mustard | italian |
                                            1000 island
                                        </p>
                                    </div>
                                    <div>
                                        <h4>SOUTHWEST SALADS</h4>
                                        <p>
                                            black beans, corn salsa, pico de
                                            gallo, cheddar cheese, avocado,
                                            crispy tortilla strips. served with
                                            spicy ranch
                                        </p>
                                    </div>
                                    <div>
                                        <h4>COBB SALAD</h4>
                                        <p>
                                            hard boiled egg, bacon, tomato, blue
                                            cheese crumbles. served with choice
                                            of dressing
                                        </p>
                                    </div>
                                    <div>
                                        <h4>TJ'S SALAD</h4>
                                        <p>
                                            tomatoes, carrots, celery, cheddar
                                            cheese, red cabbage and croutons.
                                            served with choice of dressing
                                        </p>
                                    </div>
                                    <div>
                                        <h4>CEASAR SALAD</h4>
                                        <p>parmesan cheese and croutons</p>
                                    </div>
                                </div>
                            </div>
                            <div className="rightside">
                                <div className="entrees">
                                    <h2>ENTREES</h2>
                                    <div className="firstElement">
                                        <h4>CHICKEN TENDERS</h4>
                                        <p>served with ranch</p>
                                    </div>
                                    <div>
                                        <h4>B.L.T.</h4>
                                        <p>served on potato bread</p>
                                    </div>
                                    <div>
                                        <h4>PHILLY CHEESE SAMMIE</h4>
                                        <p>
                                            sliced ribeye steak grilled with
                                            provolone, peppers, mushrooms and
                                            onions
                                        </p>
                                    </div>
                                    <div>
                                        <h4>PATTY MELT</h4>
                                    </div>
                                    <div>
                                        <h4>REUBEN</h4>
                                    </div>
                                    <div>
                                        <h4>GRILLED CHEESE</h4>
                                        <p>option to add ham</p>
                                    </div>
                                    <div>
                                        <h4>BUFFALO CHICKEN WRAP</h4>
                                        <p>
                                            fried chicken, buffalo sauce, blue
                                            cheese crumbles, lettuce, tomato,
                                            ranch
                                        </p>
                                    </div>
                                    <div>
                                        <h4>TURKEY AVOCADO SAMMIE</h4>
                                        <p>
                                            turkey, bacon, avocado on potato
                                            bread with lettuce and mayo
                                        </p>
                                    </div>
                                    <div>
                                        <h4>BURRITO</h4>
                                        <p>
                                            bean burrito smothered in our
                                            homemade green chili, with your
                                            choice of pork, chicken, or beef.
                                            Topped with cheese, sour cream,
                                            lettuce and pico de gallo
                                        </p>
                                    </div>
                                    <div>
                                        <h4>FRIED CHIMICHANGA</h4>
                                    </div>
                                    <div>
                                        <h4>CORN DOGS</h4>
                                    </div>
                                    <div>
                                        <h4>CHILI CHEESE DOG</h4>
                                        <p>
                                            two all beef hot dogs topped with
                                            Tammy's homemade chili and shredded
                                            cheese
                                        </p>
                                    </div>
                                </div>
                                <div className="divider"></div>
                                <div className="burgers">
                                    <h2 className="BYO">B.Y.O. BURGER</h2>
                                    <p className="burgerDescription">
                                        1/2 pound patty, served on a brioche bun
                                        with lettuce, tomato, onion and mayo.
                                        served with choice of side
                                    </p>
                                    <div>
                                        <h4>CHEESE OPTIONS</h4>
                                        <p>
                                            american | swiss | pepperjack |
                                            cheddar | jalapeno cream cheese |
                                            provolone
                                        </p>
                                    </div>
                                    <div>
                                        <h4>ADD ONS</h4>
                                        <p>
                                            jalapenos | grilled onions | bbq
                                            sauce | buffalo sauce | pickles |
                                            coleslaw
                                        </p>
                                    </div>
                                    <div>
                                        <h4>PREMIUM ADD ONS</h4>
                                        <p>
                                            avocado | green chili | bacon | 2
                                            onion rings | egg your way
                                        </p>
                                    </div>
                                    <div>
                                        <h4>ADD 2 JALAPENO POPPERS</h4>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="divider"></div>
                        <div className="sides">
                            <h2>SIDES</h2>
                            <div>
                                <h4 className="firstDessert">fries</h4>
                            </div>
                            <div>
                                <h4>sweet potato fries</h4>
                            </div>
                            <div>
                                <h4>tater tots</h4>
                            </div>
                            <div>
                                <h4>onion rings</h4>
                            </div>
                            <div>
                                <h4>pub chips</h4>
                            </div>
                            <div>
                                <h4>TJ's side salad</h4>
                            </div>
                            <div>
                                <h4>potato soup</h4>
                            </div>
                        </div>
                        <div className="divider"></div>
                        <div className="desserts">
                            <h2>DESSERTS</h2>
                            <div>
                                <h4 className="firstDessert">
                                    VANILLA ICE CREAM
                                </h4>
                                <p>served with chocolate and caramel drizzle</p>
                            </div>
                            <div>
                                <h4>CHOCOLATE CHOCOLATE CHIP BROWNIE</h4>
                                <p>
                                    scoop of vanilla ice cream with caramel
                                    drizzle
                                </p>
                            </div>
                            <div>
                                <h4>SKILLET COOKIE</h4>
                                <p>
                                    scoop of vanilla ice cream with chocolate
                                    and caramel drizzle
                                </p>
                            </div>
                        </div>
                    </>
                )}
                {menuType === "beverages" && (
                    <section className="food-container">
                        <div className="leftside">
                            <div className="beer">
                                <h2>BEER</h2>
                                <div className="beerDiscription">
                                    <h3>DRAFT BEER</h3>
                                    <p>16 oz and 32 oz options</p>
                                </div>
                                <div>
                                    <h4>COORS LIGHT</h4>
                                </div>
                                <div>
                                    <h4>COORS BANQUET</h4>
                                </div>
                                <div>
                                    <h4>BUD LIGHT</h4>
                                </div>
                                <div>
                                    <h4>BUDWEISER</h4>
                                </div>
                                <div>
                                    <h4>MICHELOB ULTRA</h4>
                                </div>
                                <div>
                                    <h4>MEXICAN LAGER</h4>
                                </div>
                                <div>
                                    <h4>SHOCK TOP</h4>
                                </div>
                                <div>
                                    <h4>HOPPY IPA</h4>
                                </div>
                                <div>
                                    <h4>ODELL 90 SHILLING</h4>
                                </div>
                                <div>
                                    <h4>BRECKENRIDGE AVALANCHE AMBER</h4>
                                </div>
                                <div>
                                    <h4>HAZY IPA</h4>
                                </div>
                                <div>
                                    <h4>SEASONAL ROTATING BEERS</h4>
                                </div>
                                <div className="divider"></div>
                                <div className="beerDiscription bottlespacer">
                                    <h3>BOTTLE | CANS</h3>
                                </div>
                                <div>
                                    <h4>HARD SELTZERS</h4>
                                    <p>white claw or truly</p>
                                </div>
                                <div>
                                    <h4>DOMESTIC BOTTLES</h4>
                                    <p>
                                        budweiserm bud light, coors banquet,
                                        coors light, miller lite
                                    </p>
                                </div>
                                <div>
                                    <h4>CORONA BOTTLE</h4>
                                </div>
                                <div>
                                    <h4>MODELO BOTTLE</h4>
                                </div>
                                <div>
                                    <h4>ANGRY ORCHARD BOTTLE</h4>
                                </div>
                                <div>
                                    <h4>NON-ALCOHOLIC BEER</h4>
                                </div>
                            </div>
                        </div>
                        <div className="rightside">
                            <h2 className="cocktails">COCKTAILS</h2>
                            <div>
                                <h4>BLUEBERRY MOJITO</h4>
                                <p>
                                    coconut rum, blueberry, mint, soda and lime
                                </p>
                            </div>
                            <div>
                                <h4>PINEAPPLE MARTINI</h4>
                                <p>orange vodka infused with pineapple</p>
                            </div>
                            <div>
                                <h4>TROPICAL MULE</h4>
                                <p>
                                    orange vodka and peach schnapps, pineapple
                                </p>
                            </div>
                            <div>
                                <h4>MOONSHINE MULE</h4>
                                <p>
                                    strawberry lemonade or sweet tea moonshine
                                    with ginger beer
                                </p>
                            </div>
                            <div>
                                <h4>PEACH PRESS</h4>
                                <p>peach vodka soda sprite and peach puree</p>
                            </div>
                            <div>
                                <h4>JALAPENO PINEAPPLE MARGARITA</h4>
                                <p>
                                    tequila, sour, triple sec, jalapenos and
                                    pineapple
                                </p>
                            </div>
                            <div>
                                <h4>RASPBERRY LEMONADE</h4>
                                <p>
                                    raspberry vodka, lemonade, raspberry puree
                                </p>
                            </div>
                            <div>
                                <h4>TJ'S MARGARITA</h4>
                            </div>
                            <div className="divider"></div>
                            <h2 className="wine">WINE</h2>
                            <div>
                                <h4>PINOT GRIGIO</h4>
                            </div>
                            <div>
                                <h4>CHARDONNAY</h4>
                            </div>
                            <div>
                                <h4>WHITE ZIN</h4>
                            </div>
                            <div>
                                <h4>MOSCATO</h4>
                            </div>
                            <div>
                                <h4>SPARKLING WINE</h4>
                            </div>
                            <div>
                                <h4>PINOT NOIR</h4>
                            </div>
                            <div>
                                <h4>CABERNET</h4>
                            </div>
                            <div>
                                <h4>MERLOT</h4>
                            </div>
                        </div>
                    </section>
                )}
                {menuType === "specials" && (
                    <section className="food-container specials">
                        <div className="happyHour">
                            <h2>HAPPY HOUR</h2>
                            <div>
                                <h4>MONDAY - FRIDAY</h4>
                                <p>11:00AM to 6:00PM</p>
                                <p>$1.00 off well, wine and drafts</p>
                            </div>
                        </div>
                        <div className="bufferDiv"></div>
                    </section>
                )}
                <Footer />
            </div>
        </div>
    );
};

export default Menu;
