import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import "../styles/Navbar.css"; // Ensure your CSS styles are correctly imported

const Navbar = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const location = useLocation(); // Hook to get the current location

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 700);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handlePopoverToggle = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };

    const renderMenu = () => {
        if (isSmallScreen) {
            return (
                <div className="popover-menu-container">
                    <div className="mobileNav">
                        {/* <Link to="/">
                            <img
                                src="/images/fullLogo.png"
                                alt="Home"
                                className="smallimages"
                            />
                        </Link> */}
                        <Link to="/">
                            <h2>TJ'S SPORTS BAR & GRILL</h2>
                        </Link>
                        {isPopoverOpen ? (
                            <FontAwesomeIcon
                                icon={faClose}
                                onClick={handlePopoverToggle}
                                className="menuBars"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faBars}
                                onClick={handlePopoverToggle}
                                className="menuBars"
                            />
                        )}
                    </div>
                    {isPopoverOpen && (
                        <>
                            <div className="popover-menu">
                                <Link
                                    to="/"
                                    className={
                                        location.pathname === "/"
                                            ? "selectedLink"
                                            : ""
                                    }
                                >
                                    Home
                                </Link>
                                <Link
                                    to="/menu"
                                    className={
                                        location.pathname === "/menu"
                                            ? "selectedLink"
                                            : ""
                                    }
                                >
                                    Menu
                                </Link>
                                <Link
                                    to="/events"
                                    className={
                                        location.pathname === "/events"
                                            ? "selectedLink"
                                            : ""
                                    }
                                >
                                    Events
                                </Link>
                                <a
                                    href="https://www.toasttab.com/local/order/tj-s-sports-bar-7893-w-jewell-ave/r-cb88eb23-32d3-410e-96dc-7651bea21d8c"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={
                                        location.pathname === "/signin"
                                            ? "selectedLink"
                                            : ""
                                    }
                                >
                                    Order Online
                                </a>
                                <Link
                                    to="/apparel"
                                    className={
                                        location.pathname === "/apparel"
                                            ? "selectedLink"
                                            : ""
                                    }
                                >
                                    Apparel
                                </Link>
                            </div>
                            <div className="blur"></div>
                        </>
                    )}
                </div>
            );
        } else {
            return (
                <nav className="navbar">
                    <div className="titleNav">
                        <Link to="/">
                            <img
                                src="/images/fullLogo.png"
                                alt="Home"
                                className="images"
                            />
                        </Link>
                        <h2>TJ'S SPORTS BAR & GRILL</h2>
                    </div>
                    <ul>
                        <li>
                            <Link
                                to="/"
                                className={
                                    location.pathname === "/"
                                        ? "selectedLink"
                                        : ""
                                }
                            >
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/menu"
                                className={
                                    location.pathname === "/menu"
                                        ? "selectedLink"
                                        : ""
                                }
                            >
                                Menu
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/events"
                                className={
                                    location.pathname === "/events"
                                        ? "selectedLink"
                                        : ""
                                }
                            >
                                Events
                            </Link>
                        </li>
                        <li>
                            <a
                                href="https://www.toasttab.com/local/order/tj-s-sports-bar-7893-w-jewell-ave/r-cb88eb23-32d3-410e-96dc-7651bea21d8c"
                                target="_blank" // Opens the link in a new tab
                                rel="noopener noreferrer" // Security measure for links opening in a new tab
                                className={
                                    location.pathname === "/signin"
                                        ? "selectedLink"
                                        : ""
                                }
                            >
                                Order Online
                            </a>
                        </li>
                        <li>
                            <Link
                                to="/apparel"
                                className={
                                    location.pathname === "/apparel"
                                        ? "selectedLink"
                                        : ""
                                }
                            >
                                Apparel
                            </Link>
                        </li>
                    </ul>
                </nav>
            );
        }
    };

    return <div>{renderMenu()}</div>;
};

export default Navbar;
