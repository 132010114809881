import "./App.css";
import HomePage from "./components/HomePage";
import Menu from "./components/Menu";
import SignIn from "./components/SignIn";
import CalendarPage from "./components/CalendarPage";
import Apparel from "./components/Apparel";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import { useEffect, useState } from "react";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    const [isAdmin, setIsAdmin] = useState(false);
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<HomePage isAdmin={isAdmin} />} />
                <Route path="/menu" element={<Menu isAdmin={isAdmin} />} />
                <Route
                    path="/signin"
                    element={<SignIn setIsAdmin={setIsAdmin} />}
                />
                <Route
                    path="/events"
                    element={<CalendarPage isAdmin={isAdmin} />}
                />
                <Route path="/apparel" element={<Apparel />} />
            </Routes>
        </Router>
    );
}

export default App;
