import React, { useState, useEffect } from "react";
import "../styles/Apparel.css";
import Navbar from "./NavBar";

const Apparel = () => {
    const images = [
        "./images/hat.jpeg",
        "./images/tanktop.jpeg",
        "./images/tshirt.jpeg",
    ];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex(
                (prevIndex) => (prevIndex + 1) % images.length
            );
        }, 3000);
        return () => clearInterval(intervalId);
    }, [images.length]);

    return (
        <div className="apparel-container">
            <Navbar />
            <div className="merch">
                <h2>TJ'S APPAREL</h2>
                <h3>Swing on in to grab your merch!</h3>
                <img src={images[currentImageIndex]} alt="merch" />
            </div>
        </div>
    );
};

export default Apparel;
