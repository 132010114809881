import React, { useState, useEffect } from "react";
import "../styles/HomePage.css";
import NavBar from "./NavBar";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import { collection, getDocs } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import ReactDOMServer from "react-dom/server";
import Footer from "./Footer";
import { Link } from "react-router-dom";

// Initialize Firebase
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
    authDomain: "tjsportsbar-b2424.firebaseapp.com",
    projectId: "tjsportsbar-b2424",
    storageBucket: "tjsportsbar-b2424.appspot.com",
    messagingSenderId: "916749962972",
    appId: "1:916749962972:web:9603ea2103f1d275a72e19",
    measurementId: "G-GGYXJNGSC8",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

// Add Font Awesome icon to library
library.add(faMapPin);

const HomePage = ({ ...props }) => {
    const [nextEvent, setNextEvent] = useState(null);
    const positions = [39.68375556947566, -105.08563487116287];

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const querySnapshot = await getDocs(
                    collection(firestore, "events")
                );
                const eventData = [];
                querySnapshot.forEach((doc) => {
                    const eventDate = new Date(doc.data().date);
                    eventDate.setHours(0, 0, 0, 0);
                    let currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0);

                    if (eventDate >= currentDate) {
                        eventData.push({
                            id: doc.id,
                            ...doc.data(),
                            date: eventDate,
                        });
                    }
                });

                eventData.sort((a, b) => {
                    const dateA = a.date.setHours(0, 0, 0, 0);
                    const dateB = b.date.setHours(0, 0, 0, 0);
                    if (dateA !== dateB) {
                        return dateA - dateB;
                    }
                    const [hoursA, minutesA] = a.time.split(":").map(Number);
                    const [hoursB, minutesB] = b.time.split(":").map(Number);
                    return hoursA * 60 + minutesA - (hoursB * 60 + minutesB);
                });
                console.log(eventData.length);

                if (eventData.length > 0) {
                    setNextEvent(eventData[0]);
                }
            } catch (error) {
                console.error("Error fetching events:", error);
            }
        };
        fetchEvents();
    }, []);

    const convertToAMPM = (militaryTime) => {
        const [hours, minutes] = militaryTime.split(":");
        const hour = parseInt(hours, 10);
        const ampm = hour >= 12 ? "PM" : "AM";
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        return `${formattedHour}:${minutes} ${ampm}`;
    };

    const pinIcon = L.divIcon({
        className: "custom-icon",
        html: ReactDOMServer.renderToString(
            <FontAwesomeIcon
                icon={faMapPin}
                style={{ color: "red", fontSize: "24px" }}
            />
        ),
        iconSize: [30, 30],
    });

    const handleMarkerClick = () => {
        window.open(
            "https://www.google.com/maps/place/TJ's+Sports+Bar+%26+Grill/@39.6838087,-105.0856355,21z/data=!4m6!3m5!1s0x876b8194eb1c843b:0xbf256f439916e507!8m2!3d39.6837477!4d-105.0857028!16s%2Fg%2F11hk5xf1_x?entry=ttu",
            "_blank"
        );
    };

    const [currentSlide, setCurrentSlide] = useState(0);
    const slides = [
        {
            src: "./images/allApps0.jpg",
            alt: "Locally owned and operated in Lakewood, Colorado with a homemade menu mastered by an executive chef, sampled and approved by family and friends.",
        },
        {
            src: "./images/allApps1.jpg",
            alt: "Experience an array of captivating events including sports games, trivia nights, and more. Join us and indulge in a vibrant atmosphere filled with entertainment and camaraderie.",
        },
        {
            src: "./images/allApps2.jpg",
            alt: "See our Calendar to find out more about upcoming events and special nights!",
        },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((current) => (current + 1) % slides.length);
        }, 7000); // Rotate every 3 seconds for better visibility during testing
        return () => clearInterval(interval);
    }, [slides.length]);

    const addOneDay = (date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        return newDate;
    };

    return (
        <div className="Homepage-Container">
            <NavBar />
            <div className="slideshow-container">
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className={`mySlides ${
                            index === currentSlide ? "active" : ""
                        }`}
                        style={{ left: index === currentSlide ? "0" : "100%" }}
                    >
                        <img src={slide.src} alt={`Slide ${index + 1}`} />
                        <div className="text">
                            <h4>{slide.alt}</h4>
                            <Link to="/menu">
                                <button className="mainlinkbtn peepMenu">
                                    View Our Menu
                                </button>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
            <div className="happyHourMain">
                <div className="happyHour">
                    <h2>HAPPY HOUR</h2>
                    <div>
                        <h4>MONDAY - FRIDAY</h4>
                        <h3>11:00AM to 6:00PM</h3>
                        <p>$1.00 off well, wine and drafts</p>
                    </div>
                    <Link to="/menu">
                        <button className="mainlinkbtn">View Our Menu</button>
                    </Link>
                </div>
            </div>
            <div className="eventsMain">
                <div className="eventsDiv">
                    <h2>UPCOMING EVENTS</h2>
                    <div>
                        {nextEvent ? (
                            <div>
                                <h4>{nextEvent.name}</h4>
                                <h3>
                                    {addOneDay(nextEvent.date).toLocaleDateString(
                                    "en-US"
                                )} at{" "}
                                    {convertToAMPM(nextEvent.time)}
                                </h3>
                                <p>{nextEvent.description}</p>
                                <Link to="/events">
                                    <button className="mainlinkbtn">
                                        See More Events
                                    </button>
                                </Link>
                            </div>
                        ) : (
                            <p>No upcoming events found</p>
                        )}
                    </div>
                </div>
                <div>
                    <img src="./images/firepit.jpeg" alt="fireplace" />
                </div>
            </div>

            <div className="eventsTittle">
                <MapContainer
                    center={positions}
                    zoom={17}
                    style={{ height: "400px" }}
                    className="mapContainer"
                >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Marker
                        position={positions}
                        icon={pinIcon}
                        eventHandlers={{ click: handleMarkerClick }}
                    >
                        <Popup>7893 W Jewell Ave, Lakewood, CO 80232</Popup>
                    </Marker>
                </MapContainer>
            </div>
            <Footer />
        </div>
    );
};

export default HomePage;
